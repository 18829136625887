import ApiService from '@core/api/services/ApiService';
import { Device, until } from '@helpers/media';
import { withMotionMax } from '@hoc/withMotionMax';
import useMedia from '@hooks/useMedia';
import { ReactComponent as Close } from '@img/ysp/icon-cross.svg';
import YSLogo from '@img/ysp/ys-no-strapline.png';
import YSPLogo from '@img/ysp/ysp-header-logo.png';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import Modal from '@ys/Components/Modal/Modal';
import StepHeader from '@ys/Components/StepHeader/StepHeader';
import StepPicker from '@ys/Components/StepPicker/StepPicker';
import StepRouter from '@ys/Components/StepRouter/StepRouter';
import { YourSolutionStyles as S } from '@ys/YourSolution.styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import BackToMainSite from './Components/BackToMainSite/BackToMainSite';
import StepFooter from './Components/StepFooter/StepFooter';
import Tabs from './Components/Tabs/Tabs';
import { DimensionsStepID, SoilGroundConditionsStepID, StepID, Tab, } from './Context/types';
import { MediaComponent } from '@helpers/mediaComponent';
const YourSolution = (props) => {
    const { state, ...rest } = useYSPContext();
    const stateRef = useRef(state);
    const modal = useRef(null);
    const scrollableArea = useRef(null);
    const modalScrollable = useRef(null);
    const modalKey = JSON.stringify(state.modal);
    const isMobile = useMedia([until(Device.TabletLarge)], [true], false);
    React.useEffect(() => {
        const parsed = new URLSearchParams(window.location.search);
        if (parsed.get('tab') === 'ysp') {
            rest.dispatch({ type: 'set-tab', payload: Tab.YourSolutionPlus });
        }
    }, []);
    useEffect(() => {
        // I have no idea what that body-scroll-lock thing does, but it doesn't work on iOS at all. //
        // It seems to add 'position: fixed' to the body element which just breaks tons of stuff. //
        // Surely just lock the body at 100vh height and disable overflow? //
        // The package also seems unmaintained since 2 years ago, so I'm just going to do that, which works. //
        const bodyElement = document.querySelector('body');
        if (bodyElement) {
            let existingStyleAttribute = bodyElement.getAttribute('style') || '';
            existingStyleAttribute += ' max-height: 100vh; overflow: hidden';
            bodyElement.setAttribute('style', existingStyleAttribute);
        }
        return function () {
            console.log('Unloading YS component');
            const bodyElement = document.querySelector('body');
            if (bodyElement) {
                const existingStyleAttribute = bodyElement.getAttribute('style') || '';
                existingStyleAttribute.replace('max-height: 100vh; overflow: hidden', '');
                bodyElement.setAttribute('style', existingStyleAttribute);
            }
        };
    }, []);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    const handleUnload = async () => {
        // Ensure user has at least progressed past the location step so we have contact details & postcode //
        if (stateRef.current.form.postCode !== undefined &&
            stateRef.current.step !== StepID.SelectedSolution) {
            const controllerName = stateRef.current.tab === Tab.YourSolution ? 'ISM' : 'YSP';
            let stepId = stateRef.current.step;
            if (stateRef.current.tab === Tab.YourSolutionPlus) {
                switch (stepId) {
                    case StepID.TermsAndConditions:
                        stepId = StepID.Landing; // Landing step returns Terms & Conditions content //
                        break;
                    case StepID.Dimensions:
                        switch (stateRef.current.dimensionsStep) {
                            case DimensionsStepID.EndSafePanels:
                            case DimensionsStepID.EndSafeSides:
                                stepId = 5;
                                break;
                            default:
                            case DimensionsStepID.Size:
                                break;
                        }
                        break;
                    case StepID.SoilsGroundConditions:
                        switch (state.soilGroundConditionsStep) {
                            case SoilGroundConditionsStepID.SoilInformationSource:
                                stepId = 7;
                                break;
                            case SoilGroundConditionsStepID.Groundwater:
                            case SoilGroundConditionsStepID.GroundwaterDepth:
                                stepId = 8;
                                break;
                            default:
                            case SoilGroundConditionsStepID.SoilProfiles:
                                stepId = 6;
                                break;
                        }
                        break;
                    case StepID.Surcharges:
                        stepId = 9;
                        break;
                    case StepID.Solutions:
                        stepId = 10;
                        break;
                    default:
                    case StepID.Landing:
                    case StepID.Details:
                    case StepID.Location:
                    case StepID.Depth:
                        break;
                }
            }
            await ApiService.request({
                controller: controllerName,
                slug: 'partial-submit',
                method: 'POST',
                params: {
                    ...stateRef.current.form,
                    lastStep: stepId,
                },
            });
        }
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);
        return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);
    useEffect(() => {
        rest.dispatch({ type: 'set-mobile', payload: isMobile });
    }, [isMobile]);
    useLayoutEffect(() => {
        if (!modalScrollable.current) {
            return;
        }
        if (!state.modal) {
            enableBodyScroll(modalScrollable.current);
        }
        else {
            disableBodyScroll(modalScrollable.current);
        }
        // Clear all locks when ISM component is unmounted.
        return () => {
            if (!modalScrollable.current) {
                return;
            }
            enableBodyScroll(modalScrollable.current);
        };
    }, [modalKey]);
    return (React.createElement(S.Container, { ...props, step: state.step, ref: modal },
        React.createElement(S.Header, null,
            React.createElement(AnimatePresence, { exitBeforeEnter: true }, state.step === StepID.Landing ? (React.createElement(Tabs, { key: "tabs", closeModal: props.closeModal })) : (React.createElement(S.MainHeaderContentWrapper, { key: "header" },
                React.createElement(StepHeader, null),
                React.createElement(S.MobileHeader, null,
                    state.tab === Tab.YourSolution ? (React.createElement(S.Image, { src: YSLogo })) : (React.createElement(S.Image, { src: YSPLogo })),
                    React.createElement(S.Close, { onClick: () => props.closeModal && props.closeModal() },
                        React.createElement(Close, null))),
                state.step !== StepID.TermsAndConditions && React.createElement(StepPicker, null))))),
        React.createElement(S.ModalInner, null,
            React.createElement(S.Content, { isLandingPage: state.step === StepID.Landing, locked: state.scrollLocked, ref: scrollableArea, soilModalOpen: state.soilModalOpen },
                React.createElement(StepRouter, { state: state, ...rest })),
            React.createElement(AnimatePresence, null, state.tab === Tab.YourSolutionPlus && React.createElement(S.BackgroundImage, null))),
        React.createElement(AnimatePresence, null, state.modal !== undefined && (React.createElement(Modal, { key: `YourSolution_Modal`, modal: state.modal, modalScrollable: modalScrollable }))),
        React.createElement(AnimatePresence, null,
            React.createElement(MediaComponent, { mobile: true },
                React.createElement(StepFooter, { key: "StepFooter", state: state, ...rest })),
            React.createElement(MediaComponent, { desktop: true },
                state.step === StepID.Landing && (React.createElement(BackToMainSite, { closeModal: props.closeModal, key: "BackToMainSite" })),
                state.step > StepID.Landing && React.createElement(StepFooter, { key: "StepFooter", state: state, ...rest })))));
};
export default withMotionMax(YourSolution);
