export var SupportType;
(function (SupportType) {
    SupportType["Box"] = "BOX";
    SupportType["Frame"] = "FRAME";
})(SupportType || (SupportType = {}));
export var SolutionType;
(function (SolutionType) {
    SolutionType["SheetsAndManholeBrace"] = "B";
    SolutionType["SheetsAndWalers"] = "W";
    SolutionType["ManholeBox"] = "MB";
    SolutionType["TrenchBox"] = "TB";
    SolutionType["Lightweight"] = "LW";
})(SolutionType || (SolutionType = {}));
export var SolutionSubType;
(function (SolutionSubType) {
    SolutionSubType["None"] = "";
    SolutionSubType["SingleFrame"] = "1F";
    SolutionSubType["TwoFrame"] = "2F";
    SolutionSubType["BackhoeManholeBox"] = "BH";
    SolutionSubType["BackHoeManholeBoxAndTop"] = "BH";
    SolutionSubType["MultiManholeBox"] = "MB";
    SolutionSubType["MultiManholeBoxAndTop"] = "MB";
    SolutionSubType["BackhoeTrenchBox"] = "BH";
    SolutionSubType["BackhoeTrenchBoxAndTop"] = "BH";
    SolutionSubType["MiniTrenchBox"] = "MI";
    SolutionSubType["MiniTrenchBoxAndTop"] = "MI";
    SolutionSubType["StandardTrenchBox"] = "SD";
    SolutionSubType["StandardTrenchBoxAndTop"] = "SD";
    SolutionSubType["PremierTrenchBox"] = "PR";
    SolutionSubType["RollingStrutTrenchBox"] = "PS";
    SolutionSubType["MapBox"] = "MAP";
    SolutionSubType["QuickAccessBox"] = "QAB";
})(SolutionSubType || (SolutionSubType = {}));
export var SoilType;
(function (SoilType) {
    SoilType["Cohesive"] = "CO";
    SoilType["Granular"] = "GR";
})(SoilType || (SoilType = {}));
export var StepID;
(function (StepID) {
    StepID[StepID["Landing"] = 0] = "Landing";
    StepID[StepID["Details"] = 1] = "Details";
    StepID[StepID["Location"] = 2] = "Location";
    StepID[StepID["Depth"] = 3] = "Depth";
    StepID[StepID["Dimensions"] = 4] = "Dimensions";
    StepID[StepID["Support"] = 5] = "Support";
    StepID[StepID["Type"] = 6] = "Type";
    StepID[StepID["Conditions"] = 7] = "Conditions";
    StepID[StepID["Surcharges"] = 8] = "Surcharges";
    StepID[StepID["Solutions"] = 9] = "Solutions";
    StepID[StepID["TermsAndConditions"] = 10] = "TermsAndConditions";
    StepID[StepID["SoilsGroundConditions"] = 11] = "SoilsGroundConditions";
    StepID[StepID["SelectedSolution"] = 12] = "SelectedSolution";
})(StepID || (StepID = {}));
export var SupportStepID;
(function (SupportStepID) {
    SupportStepID[SupportStepID["System"] = 0] = "System";
    SupportStepID[SupportStepID["SheetedSolution"] = 1] = "SheetedSolution";
})(SupportStepID || (SupportStepID = {}));
export var ConditionStepID;
(function (ConditionStepID) {
    ConditionStepID[ConditionStepID["ProblematicGround"] = 0] = "ProblematicGround";
    ConditionStepID[ConditionStepID["SoilType"] = 1] = "SoilType";
    ConditionStepID[ConditionStepID["GroundWater"] = 2] = "GroundWater";
    ConditionStepID[ConditionStepID["GroundWaterDepth"] = 3] = "GroundWaterDepth";
})(ConditionStepID || (ConditionStepID = {}));
export var SurchargeStepID;
(function (SurchargeStepID) {
    SurchargeStepID[SurchargeStepID["LargeSurcharge"] = 0] = "LargeSurcharge";
    SurchargeStepID[SurchargeStepID["SlopingGround"] = 1] = "SlopingGround";
    SurchargeStepID[SurchargeStepID["AdjacentWater"] = 2] = "AdjacentWater";
    SurchargeStepID[SurchargeStepID["ZoneOfInfluence"] = 3] = "ZoneOfInfluence";
})(SurchargeStepID || (SurchargeStepID = {}));
export var DimensionsStepID;
(function (DimensionsStepID) {
    DimensionsStepID[DimensionsStepID["Size"] = 0] = "Size";
    DimensionsStepID[DimensionsStepID["Type"] = 1] = "Type";
    DimensionsStepID[DimensionsStepID["EndSafePanels"] = 2] = "EndSafePanels";
    DimensionsStepID[DimensionsStepID["EndSafeSides"] = 3] = "EndSafeSides";
})(DimensionsStepID || (DimensionsStepID = {}));
export var SoilGroundConditionsStepID;
(function (SoilGroundConditionsStepID) {
    SoilGroundConditionsStepID[SoilGroundConditionsStepID["SoilProfiles"] = 0] = "SoilProfiles";
    SoilGroundConditionsStepID[SoilGroundConditionsStepID["SoilInformationSource"] = 1] = "SoilInformationSource";
    SoilGroundConditionsStepID[SoilGroundConditionsStepID["Groundwater"] = 2] = "Groundwater";
    SoilGroundConditionsStepID[SoilGroundConditionsStepID["GroundwaterDepth"] = 3] = "GroundwaterDepth";
})(SoilGroundConditionsStepID || (SoilGroundConditionsStepID = {}));
export var OptionalType;
(function (OptionalType) {
    OptionalType[OptionalType["Groundwater"] = 0] = "Groundwater";
    OptionalType[OptionalType["EndSafe"] = 1] = "EndSafe";
    OptionalType[OptionalType["Surcharges"] = 2] = "Surcharges";
    OptionalType[OptionalType["SheetedSolution"] = 3] = "SheetedSolution";
})(OptionalType || (OptionalType = {}));
export const YourSolutionProgression = [
    { id: 0, type: 'default', step: StepID.Landing, title: 'Home' },
    { id: 1, type: 'default', step: StepID.Details, title: 'Your Details' },
    { id: 2, type: 'default', step: StepID.Location, title: 'Location' },
    { id: 3, type: 'default', step: StepID.Depth, title: 'Depth' },
    { id: 4, type: 'default', step: StepID.Dimensions, title: 'Plan Dimensions' },
    { id: 5, type: 'support', step: SupportStepID.System, title: 'Support System' },
    {
        id: 6,
        type: 'support',
        step: SupportStepID.SheetedSolution,
        optional: OptionalType.SheetedSolution,
    },
    { id: 7, type: 'default', step: StepID.Type, title: 'Product Type' },
    {
        id: 8,
        type: 'conditions',
        step: ConditionStepID.ProblematicGround,
        title: 'Soils / Ground Conditions',
    },
    { id: 9, type: 'conditions', step: ConditionStepID.SoilType },
    { id: 10, type: 'conditions', step: ConditionStepID.GroundWater },
    {
        id: 11,
        type: 'conditions',
        step: ConditionStepID.GroundWaterDepth,
        optional: OptionalType.Groundwater,
    },
    {
        id: 12,
        type: 'surcharges',
        step: SurchargeStepID.LargeSurcharge,
        title: 'Surcharges & Site Risks',
    },
    { id: 13, type: 'surcharges', step: SurchargeStepID.SlopingGround },
    { id: 14, type: 'surcharges', step: SurchargeStepID.AdjacentWater },
    { id: 15, type: 'default', step: StepID.Solutions, title: 'Solution Selection Assessment' },
];
export const YourSolutionPlusProgression = [
    { id: 0, type: 'default', step: StepID.Landing, title: 'Home' },
    { id: 1, type: 'default', step: StepID.TermsAndConditions, title: 'Terms & Conditions' },
    { id: 2, type: 'default', step: StepID.Details, title: 'Your Details' },
    { id: 3, type: 'default', step: StepID.Location, title: 'Location' },
    { id: 4, type: 'default', step: StepID.Depth, title: 'Depth' },
    { id: 5, type: 'dimensions', step: DimensionsStepID.Size, title: 'Plan Dimensions' },
    { id: 6, type: 'dimensions', step: DimensionsStepID.Type, title: 'Product Type' },
    { id: 7, type: 'dimensions', step: DimensionsStepID.EndSafePanels },
    {
        id: 8,
        type: 'dimensions',
        step: DimensionsStepID.EndSafeSides,
        optional: OptionalType.EndSafe,
    },
    {
        id: 9,
        type: 'soil-ground-conditions',
        step: SoilGroundConditionsStepID.SoilProfiles,
        title: 'Soils/Ground Conditions',
    },
    {
        id: 10,
        type: 'soil-ground-conditions',
        step: SoilGroundConditionsStepID.SoilInformationSource,
    },
    { id: 11, type: 'soil-ground-conditions', step: SoilGroundConditionsStepID.Groundwater },
    {
        id: 12,
        type: 'soil-ground-conditions',
        step: SoilGroundConditionsStepID.GroundwaterDepth,
        optional: OptionalType.Groundwater,
    },
    {
        id: 13,
        type: 'surcharges',
        step: SurchargeStepID.LargeSurcharge,
        title: 'Surcharges & Site Risks',
    },
    {
        id: 14,
        type: 'surcharges',
        step: SurchargeStepID.ZoneOfInfluence,
        optional: OptionalType.Surcharges,
    },
    {
        id: 15,
        type: 'default',
        step: StepID.Solutions,
        title: 'Solution Selection Assessment',
    },
    {
        id: 16,
        type: 'default',
        step: StepID.SelectedSolution,
    },
];
// DEV PURPOSES ONLY //
export const YSPQuickProgress = [
    { id: 0, type: 'default', step: StepID.Landing, title: 'Home' },
    {
        id: 1,
        type: 'soil-ground-conditions',
        step: SoilGroundConditionsStepID.SoilProfiles,
        title: 'Soils/Ground Conditions',
    },
];
export var Tab;
(function (Tab) {
    Tab[Tab["YourSolution"] = 0] = "YourSolution";
    Tab[Tab["YourSolutionPlus"] = 1] = "YourSolutionPlus";
})(Tab || (Tab = {}));
export var EndSafeSide;
(function (EndSafeSide) {
    EndSafeSide[EndSafeSide["None"] = 0] = "None";
    EndSafeSide[EndSafeSide["One"] = 1] = "One";
    EndSafeSide[EndSafeSide["Both"] = 2] = "Both";
})(EndSafeSide || (EndSafeSide = {}));
export var SoilInfoSource;
(function (SoilInfoSource) {
    SoilInfoSource[SoilInfoSource["Verbal"] = 0] = "Verbal";
    SoilInfoSource[SoilInfoSource["BoreholeLog"] = 2] = "BoreholeLog";
})(SoilInfoSource || (SoilInfoSource = {}));
export function getInitialState(props) {
    const initialState = {
        cultureCode: props.cultureCode,
        technicalLibraryUrl: props.technicalLibraryUrl,
        yourSolutionUrl: props.yourSolutionUrl,
        modal: undefined,
        step: StepID.Landing,
        steps: YourSolutionProgression,
        conditionStep: ConditionStepID.ProblematicGround,
        surchargeStep: SurchargeStepID.LargeSurcharge,
        supportStep: SupportStepID.System,
        dimensionsStep: DimensionsStepID.Size,
        soilGroundConditionsStep: SoilGroundConditionsStepID.SoilProfiles,
        loading: false,
        labels: {},
        form: {
            depth: 1.5,
            width: 3.0,
            length: 3.0,
            subType: SolutionSubType.None,
            groundwaterDepth: 0,
            soilDisclaimer: false,
            surchargeProfiles: props?.form?.surchargeProfiles ? props?.form?.surchargeProfiles : [],
        },
        errors: {},
        stepFooter: {
            nextDisabled: false,
            type: 'default',
        },
        tab: Tab.YourSolution,
        globalStep: 0,
        progress: 0,
        scrollLocked: false,
        soilProfiles: [],
        surcharges: [],
        loadingResults: false,
        mobile: false,
        formDirtyAfterSolutionsFetch: true,
        hasChangedSelectedSolution: true,
        zoomModalOpen: false,
        soilModalOpen: false,
        hasAddedDefaultSurcharge: false,
    };
    // Populate the authenicated user
    if (props.email) {
        initialState.authenticatedUser = {
            email: props.email ? props.email : '',
            userName: props.userName ? props.userName : '',
            telephone: props.telephone ? props.telephone : '',
            companyName: props.companyName ? props.companyName : '',
        };
        // Populate form fields
        initialState.form['name'] = props.userName;
        initialState.form['email'] = props.email;
        initialState.form['telephone'] = props.telephone;
        initialState.form['companyName'] = props.companyName;
    }
    return initialState;
}
