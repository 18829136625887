import { imageUrl } from '@helpers/cloudinary';
import { useInView } from '@hooks/useInView';
import { ReactComponent as Check } from '@img/ysp/icon-check-circle.svg';
import { useYSPContext } from '@stories/Widgets/YourSolution/YS/Context/YSContext';
import YSButton from '@ys/Components/Shared/YSButton/YSButton';
import React, { useEffect, useMemo } from 'react';
import ZoomImage from '../ZoomImage/ZoomImage';
import { SolutionStyles as S } from './Solution.styles';
import { SummaryStyles } from '../Summary/Summary.styles';
export const MomentsTable = ({ solution, labels }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(S.TableHeader, null, "Moments"),
        React.createElement(S.TableContainer, { rows: 3 },
            React.createElement(S.Column, { span: 2 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { span: 2, dangerouslySetInnerHTML: { __html: labels['ysp.moment.table.corner'] } })),
                solution.moments.map((moment, i) => (React.createElement(S.Header, { key: `Solution_${i}_Name`, span: 1 }, moment.name)))),
            React.createElement(S.Column, { span: 1 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 2 }, labels['ysp.moment.table.allowable'])),
                solution.moments.map((moment, i) => (React.createElement(S.Cell, { key: `Solution_${i}_Allowable` }, moment.allowable)))),
            React.createElement(S.Column, { span: 1 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 2 }, labels['ysp.moment.table.actual'])),
                solution.moments.map((moment, i) => (React.createElement(S.Cell, { key: `Solution_${i}_Actual` }, moment.actual)))),
            React.createElement(S.Column, { isPassFailColumn: true, span: 1 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 2 }, labels['ysp.moment.table.passheading'])),
                solution.moments.map((moment, i) => moment.pass === undefined ? (React.createElement(S.Cell, { key: `Solution_${i}_Pass` }, "N/A")) : (React.createElement(S.PassFail, { pass: moment.pass, key: `Solution_${i}_Pass` }, moment.pass
                    ? labels['ysp.momentstruts.table.pass']
                    : labels['ysp.momentstruts.table.fail'])))))));
};
export const StrutsTable = ({ solution, labels }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(S.TableHeader, null, "Struts"),
        React.createElement(S.StrutsTableWrapper, { rows: 4 },
            React.createElement(S.Column, { span: 1 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Blank, null)),
                solution.struts.map((strut, i) => (React.createElement(S.Header, { center: true, key: `Solution_Struts_${i}_Name`, span: 1 }, strut.name)))),
            React.createElement(S.Column, { span: 2 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 1 }, labels['ysp.struts.table.levellabel']),
                    React.createElement(S.Header, { center: true, span: 1 }, labels['ysp.struts.table.levelunits'])),
                solution.struts.map((strut, i) => (React.createElement(S.Cell, { key: `Solution_Struts_${i}_Level` }, strut.level)))),
            React.createElement(S.Column, { span: 2 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 1, dangerouslySetInnerHTML: { __html: labels['ysp.struts.table.loadallowlabel'] } }),
                    React.createElement(S.Header, { center: true, span: 1, dangerouslySetInnerHTML: { __html: labels['ysp.struts.table.loadunits'] } })),
                solution.struts.map((strut, i) => (React.createElement(S.Cell, { key: `Solution_Struts_${i}_LoadAllow` }, strut.loadAllow)))),
            React.createElement(S.Column, { span: 2 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 1, dangerouslySetInnerHTML: { __html: labels['ysp.struts.table.loadactuallabel'] } }),
                    React.createElement(S.Header, { center: true, span: 1, dangerouslySetInnerHTML: { __html: labels['ysp.struts.table.loadunits'] } })),
                solution.struts.map((strut, i) => (React.createElement(S.Cell, { key: `Solution_Struts_${i}_LoadActual` }, strut.loadActual)))),
            React.createElement(S.Column, { isPassFailColumn: true, span: 3 },
                React.createElement(S.HeaderSection, null,
                    React.createElement(S.Header, { center: true, span: 2 }, labels['ysp.struts.table.passheading'])),
                solution.struts.map((strut, i) => strut.pass === undefined ? (React.createElement(S.Cell, { key: `Solution_Strut_${i}_Pass` }, "N/A")) : (React.createElement(S.PassFail, { pass: strut.pass, key: `Solution_Strut_${i}_Pass` }, strut.pass ? 'PASS' : 'FAIL')))))));
};
const Solution = ({ solution, i }) => {
    const [ref, inView] = useInView({ threshold: 0.15 });
    const { state, dispatch, setFormField } = useYSPContext();
    const isSelected = useMemo(() => !!state.form.selectedSolutionName && state.form.selectedSolutionName === solution.name, [state.form.selectedSolutionName, solution.name]);
    useEffect(() => {
        dispatch({
            type: 'set-footer',
            payload: {
                nextDisabled: state.form.selectedSolutionName === undefined,
            },
        });
    }, [state.form.selectedSolutionName]);
    return (React.createElement(S.Container, { selected: isSelected, "data-solution-id": solution.name, ref: ref, visible: inView },
        React.createElement(S.MobileImage, null,
            React.createElement(ZoomImage, { imageUrl: imageUrl(solution.image, `c_fill,w_360`) })),
        React.createElement(S.LeftSection, null,
            React.createElement(S.Title, null, solution.name),
            React.createElement(S.DescriptionAndTable, null,
                React.createElement(SummaryStyles.Table, { "data-solution-mode": "true" }, solution.boxInfo &&
                    solution.boxInfo.map((info, i) => (React.createElement(SummaryStyles.Row, { key: `Summary_BoxInfo_${i}` },
                        React.createElement("span", null,
                            React.createElement("strong", null,
                                info.item1,
                                ":"),
                            " ",
                            info.item2))))),
                React.createElement(S.Description, null, solution.description)),
            React.createElement(S.SolutionTag, null,
                isSelected && React.createElement(Check, null),
                " Solution ",
                i + 1),
            React.createElement(YSButton, { icon: isSelected ? 'check' : undefined, iconPosition: "left", fill: isSelected ? 'outline' : 'solid', disabled: !solution.selectable, onClick: () => {
                    if (isSelected) {
                        setFormField('selectedSolutionName', undefined);
                        setFormField('selectedSolutionIterator', undefined);
                    }
                    else {
                        setFormField('selectedSolutionName', solution.name);
                        setFormField('selectedSolutionIterator', i);
                    }
                } }, isSelected ? 'Solution Selected' : 'Select Solution'),
            !solution.selectable && (React.createElement(S.FailText, null, "To be able to select this solution all parameters must pass the tests below.")),
            React.createElement(MomentsTable, { solution: solution, labels: state.labels }),
            React.createElement(StrutsTable, { solution: solution, labels: state.labels }),
            !solution.selectable && (React.createElement(S.FailBoxWrapper, null,
                React.createElement(S.FailDescription, null, "This solution failed in the above tests. You have the opportunity to change the parameters you have entered in order to make this solution pass and become available to you."),
                React.createElement(YSButton, { colour: "orange", size: "wide", onClick: () => {
                        dispatch({ type: 'go-to-step', payload: 6 }); // ID of the first EndSafe panels question in the YSP steps //
                    } }, "Change parameters")))),
        React.createElement(S.RightSection, null,
            React.createElement("img", { src: imageUrl(solution.image, `c_fill,w_360`) }))));
};
export default Solution;
