import { ConditionStepID, DimensionsStepID, getInitialState, OptionalType, SoilGroundConditionsStepID, SolutionSubType, StepID, SupportStepID, SupportType, SurchargeStepID, Tab, 
// YSPQuickProgress,
YourSolutionPlusProgression, YourSolutionProgression, } from './types';
import isEqual from 'lodash/isEqual';
const phoneRegex = new RegExp(/^[0-9 )(+#]{10,}$/);
const emailRegex = new RegExp(
// eslint-disable-next-line no-useless-escape
/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const postcodeRegex = new RegExp(/^([A-PR-Z](([0-9](([0-9]|[A-HJKSTUWX])?)?)|([A-HK-Y][0-9]([0-9]|[ABEHMNPRVWXY])?)) ([0-9][ABD-HJLNP-UW-Z]{2}|[0-9AC-FHKNPRTV-Y]{4}))$/, 'i');
const determineStep = (state, direction) => {
    if (direction === 'next'
        ? state.globalStep + 1 <= state.steps.length - 1
        : state.globalStep - 1 >= StepID.Landing) {
        const currentStep = state.steps[state.globalStep];
        const checkStep = state.steps[direction === 'next' ? state.globalStep + 1 : state.globalStep - 1];
        if (checkStep.optional !== undefined) {
            // There's an optional condition, check it //
            switch (checkStep.optional) {
                case OptionalType.Groundwater:
                    if (!state.form.groundwaterPresent) {
                        // Skip groundwater question //
                        state.globalStep = direction === 'next' ? state.globalStep + 1 : state.globalStep - 1;
                    }
                    break;
                case OptionalType.EndSafe:
                    if (!state.form.endSafePanelsRequired) {
                        // Skip sides question //
                        state.globalStep = direction === 'next' ? state.globalStep + 1 : state.globalStep - 1;
                    }
                    break;
                case OptionalType.Surcharges:
                    if (!state.form.largeSurcharges) {
                        if (!state.hasAddedDefaultSurcharge) {
                            // Skip surcharges question, but preselect any options that come preselected from the CMS //
                            const preselectedSurcharges = state.surcharges.filter((x) => x.surchargePreselected);
                            preselectedSurcharges.forEach((surcharge) => {
                                if (!state.form.surchargeProfiles) {
                                    state.form.surchargeProfiles = [];
                                }
                                state.form.surchargeProfiles.push(surcharge);
                            });
                            state.hasAddedDefaultSurcharge = true;
                        }
                        state.globalStep = direction === 'next' ? state.globalStep + 1 : state.globalStep - 1;
                    }
                    break;
                case OptionalType.SheetedSolution:
                    if (state.form.supportType !== SupportType.Frame) {
                        // Skip sheeted solution question //
                        state.globalStep = direction === 'next' ? state.globalStep + 1 : state.globalStep - 1;
                    }
                    break;
                default:
                    break;
            }
        }
        // Will have been adjusted due to optional conditions, or not //
        const newStep = state.steps[direction === 'next' ? state.globalStep + 1 : state.globalStep - 1];
        // Progress logic //
        // Quite fiddly and probably needs rewriting somepoint, but determines if it should progress the top bar or not //
        if (currentStep.type === newStep.type) {
            // Titles almost always define a point to progress, so if we're going from a type of question to the same type //
            // and both of them have titles, modify progress //
            // We also don't want to count the Landing page as a step, so ignore it in the logic //
            if ((currentStep.title && newStep.title && currentStep.id !== StepID.Landing) ||
                (direction === 'next'
                    ? currentStep.step === StepID.TermsAndConditions
                    : newStep.step === StepID.TermsAndConditions) // A bit hacky but also want to apply this logic to the T&C step
            ) {
                state.progress = direction === 'next' ? state.progress + 1 : state.progress - 1;
            }
        }
        else {
            // Going between two different types of question - always modify, luckily factors in optional questions too //
            state.progress = direction === 'next' ? state.progress + 1 : state.progress - 1;
        }
        switch (newStep.type) {
            default:
            case 'default':
                // Normal step //
                state.step = newStep.step;
                break;
            case 'support':
                // Support step //
                state.step = StepID.Support;
                state.supportStep = newStep.step;
                break;
            case 'conditions':
                // Conditions step //
                state.step = StepID.Conditions;
                state.conditionStep = newStep.step;
                break;
            case 'surcharges':
                // Surcharges step //
                state.step = StepID.Surcharges;
                state.surchargeStep = newStep.step;
                break;
            case 'dimensions':
                // Dimensions step //
                state.step = StepID.Dimensions;
                state.dimensionsStep = newStep.step;
                break;
            case 'soil-ground-conditions':
                // Dimensions step //
                state.step = StepID.SoilsGroundConditions;
                state.soilGroundConditionsStep = newStep.step;
                break;
        }
        state.globalStep = direction === 'next' ? state.globalStep + 1 : state.globalStep - 1;
        if (state.progress < 0) {
            state.progress = 0;
        }
    }
};
export default function reducer(state, action) {
    switch (action.type) {
        /**
         * Initial setup
         */
        default:
        case 'init': {
            return state;
        }
        /**
         * Traversing the steps
         */
        case 'next-step': {
            const newState = { ...state };
            if (Object.keys(state.errors).length === 0) {
                determineStep(newState, 'next');
                // Clear any blocking conditions on the footer //
                newState.stepFooter.nextDisabled = false;
                newState.stepFooter.beforeNext = undefined;
            }
            return newState;
        }
        /**
         * Reversing through the steps
         */
        case 'previous-step': {
            const newState = { ...state };
            // As of right now, the final step is ALWAYS the solutions //
            // Meaning if you've come into this reducer, you have to clear the solutions //
            newState.results = undefined;
            determineStep(newState, 'previous');
            // Clear any blocking conditions on the footer //
            newState.stepFooter.nextDisabled = false;
            newState.stepFooter.beforeNext = undefined;
            return newState;
        }
        /**
         * Navigates to a specific step
         */
        case 'go-to-step': {
            const newState = { ...state };
            const targetStep = newState.steps[action.payload];
            const resetStepsIfNeeded = () => {
                const copy = [...newState.steps];
                const foundSteps = [targetStep.type];
                for (let i = state.globalStep; i >= action.payload; i--) {
                    if (foundSteps.indexOf(copy[i].type) === -1) {
                        foundSteps.push(copy[i].type);
                    }
                }
                foundSteps.forEach((x) => {
                    switch (x) {
                        default:
                        case 'support':
                            newState.supportStep = SupportStepID.System;
                            break;
                        case 'conditions':
                            newState.conditionStep = ConditionStepID.ProblematicGround;
                            break;
                        case 'surcharges':
                            newState.surchargeStep = SurchargeStepID.LargeSurcharge;
                            break;
                        case 'dimensions':
                            newState.dimensionsStep = DimensionsStepID.Size;
                            break;
                        case 'soil-ground-conditions':
                            newState.soilGroundConditionsStep = SoilGroundConditionsStepID.SoilProfiles;
                            break;
                    }
                });
            };
            // e.g. if we're going back past the Support step, reset support to the first step //
            resetStepsIfNeeded();
            newState.globalStep = action.payload;
            newState.stepFooter.nextDisabled = false;
            newState.stepFooter.beforeNext = undefined;
            // If you're on the final step and go back to change some stuff, we should unselect the solution //
            // as you may end up having a (now) failing solution selected when you get back to the solutions list //
            newState.selectedSolution = undefined;
            // Modify the progress too to update the header - find all the steps up until the new point that have titles //
            const stepsWithTitles = newState.steps
                .slice(0, newState.globalStep)
                .filter((x) => x.title && x.title !== 'Home').length;
            newState.progress = stepsWithTitles;
            switch (targetStep.type) {
                default:
                case 'default':
                    // Normal step //
                    newState.step = targetStep.step;
                    break;
                case 'support':
                    // Support step //
                    newState.step = StepID.Support;
                    newState.supportStep = targetStep.step;
                    break;
                case 'conditions':
                    // Conditions step //
                    newState.step = StepID.Conditions;
                    newState.conditionStep = targetStep.step;
                    break;
                case 'surcharges':
                    // Surcharges step //
                    newState.step = StepID.Surcharges;
                    newState.surchargeStep = targetStep.step;
                    break;
                case 'dimensions':
                    // Surcharges step //
                    newState.step = StepID.Dimensions;
                    newState.dimensionsStep = targetStep.step;
                    break;
                case 'soil-ground-conditions':
                    // Soils and Ground Conditions steps //
                    newState.step = StepID.SoilsGroundConditions;
                    newState.soilGroundConditionsStep = targetStep.step;
                    break;
            }
            return newState;
        }
        /**
         * Goes back to the start and clears any data
         */
        case 'restart': {
            const newState = getInitialState({
                cultureCode: state.cultureCode,
                technicalLibraryUrl: state.technicalLibraryUrl,
                yourSolutionUrl: state.yourSolutionUrl,
                email: state.authenticatedUser?.email,
                userName: state.authenticatedUser?.userName,
                telephone: state.authenticatedUser?.telephone,
                companyName: state.authenticatedUser?.companyName,
            });
            return newState;
        }
        /**
         * Submit
         */
        case 'submit': {
            const newState = { ...state };
            newState.loadingResults = true;
            return newState;
        }
        /**
         * Submit success
         */
        case 'submit-success': {
            const newState = { ...state };
            newState.results = action.payload;
            newState.loadingResults = false;
            return newState;
        }
        /**
         * Initial get step action used for loaders
         */
        case 'get-step': {
            const newState = { ...state };
            newState.loading = true;
            return newState;
        }
        /**
         * Get step action used for handling errors
         */
        case 'get-step-error': {
            const newState = { ...state };
            return newState;
        }
        /**
         * Get step action used for handling success
         */
        case 'get-step-success': {
            const newState = { ...state };
            // YSP responses are wrapped in an additional layer //
            // TODO : Make this less hacky //
            if (newState.tab === Tab.YourSolutionPlus) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const responseAny = action.payload;
                if (responseAny.result) {
                    newState.labels = responseAny.result.labels;
                }
            }
            else {
                newState.labels = action.payload.labels;
            }
            newState.loading = false;
            return newState;
        }
        /**
         * Set a form field to the given value
         */
        case 'set-form-field': {
            const newState = { ...state };
            const initialState = getInitialState({
                cultureCode: state.cultureCode,
                technicalLibraryUrl: state.technicalLibraryUrl,
                yourSolutionUrl: state.yourSolutionUrl,
                email: state.authenticatedUser?.email,
                userName: state.authenticatedUser?.userName,
                telephone: state.authenticatedUser?.telephone,
                companyName: state.authenticatedUser?.companyName,
            });
            // Only change if there's an actual change //
            if (!isEqual(newState.form[action.payload.field], action.payload.value)) {
                newState.form[action.payload.field] = action.payload.value;
                if (action.payload.invalidatedFields && action.payload.invalidatedFields.length > 0) {
                    for (const field of action.payload.invalidatedFields) {
                        newState.form[field] = initialState.form[field];
                    }
                }
                // If we've updated a form field, and we're not on solutions (i.e. a change that will affect the solutions) //
                // set the form to dirty so we can trigger a re-fetch when the solutions step is reached again //
                if (!newState.formDirtyAfterSolutionsFetch && newState.progress < StepID.Solutions) {
                    newState.formDirtyAfterSolutionsFetch = true;
                }
                if (action.payload.field === 'selectedSolutionName' &&
                    !newState.hasChangedSelectedSolution) {
                    newState.hasChangedSelectedSolution = true;
                }
            }
            return newState;
        }
        /**
         * Validates the user details
         */
        case 'validate-details': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // First Name
            if (newState.form.name === undefined || newState.form.name.length === 0) {
                newState.errors['name'] = 'custom.ism.details.name.empty';
            }
            // Email
            if (newState.form.email === undefined || newState.form.email.length === 0) {
                newState.errors['email'] = 'custom.ism.details.email.empty';
            }
            else if (newState.form.email.match(emailRegex) === null) {
                newState.errors['email'] = 'custom.ism.details.email.invalid';
            }
            // Confirm Email
            if (state.authenticatedUser === undefined &&
                (newState.form.confirmEmail?.toLowerCase() !== newState.form.email?.toLowerCase() ||
                    newState.errors['email'] !== undefined)) {
                newState.errors['confirmEmail'] = 'custom.ism.details.email.mismatch';
            }
            // Scheme Name
            if (newState.form.schemeName === undefined || newState.form.schemeName.length === 0) {
                newState.errors['schemeName'] = 'custom.ism.details.scheme.empty';
            }
            // Site Name
            if (newState.form.siteName === undefined || newState.form.siteName.length === 0) {
                newState.errors['siteName'] = 'custom.ism.details.site.empty';
            }
            // Company Name
            if (newState.form.companyName === undefined || newState.form.companyName.length === 0) {
                newState.errors['companyName'] = 'custom.ism.details.company.empty';
            }
            // Telephone
            if (newState.form.telephone === undefined || newState.form.telephone?.length === 0) {
                newState.errors['telephone'] = 'custom.ism.details.telephone.empty';
            }
            else if (newState.form.telephone?.match(phoneRegex) === null) {
                newState.errors['telephone'] = 'custom.ism.details.telephone.invalid';
            }
            // Recaptcha
            if (newState.form.recaptchaToken === undefined ||
                newState.form.recaptchaToken === null ||
                newState.form.recaptchaToken.length === 0) {
                newState.errors['recaptchaToken'] = 'custom.ism.details.recaptcha.empty';
            }
            return newState;
        }
        case 'validate-depth': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            if (newState.form.depth === undefined || newState.form.depth === 0) {
                newState.errors['depth'] = 'invalid';
            }
            return newState;
        }
        case 'validate-dimensions': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            if (newState.form.width === undefined || newState.form.width === 0) {
                newState.errors['width'] = 'invalid';
            }
            if (newState.form.length === undefined || newState.form.length === 0) {
                newState.errors['length'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates the postcode
         */
        case 'validate-postcode': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.postCode === undefined) {
                newState.errors['postCode'] = 'custom.ism.postcode.empty';
            }
            else if (newState.form.postCode.indexOf(' ') === -1) {
                newState.errors['postCode'] = 'custom.ism.postcode.missingspace';
            }
            else if (newState.form.postCode.match(postcodeRegex) === null) {
                newState.errors['postCode'] = 'custom.ism.postcode.invalid';
            }
            return newState;
        }
        /**
         * Validates the selected type
         */
        case 'validate-type': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.type === undefined) {
                newState.errors['type'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates the selected type
         */
        case 'validate-sub-type': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.subType === undefined ||
                (newState.form.supportType === SupportType.Frame &&
                    newState.form.subType === SolutionSubType.None)) {
                newState.errors['subType'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates groundwater
         */
        case 'validate-groundwater': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.groundwaterPresent === undefined) {
                newState.errors['groundwaterPresent'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates groundwater
         */
        case 'validate-groundwater-depth': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid Depth
            if (newState.form.groundwaterDepth === undefined || newState.form.groundwaterDepth < 0) {
                newState.errors['groundwaterDepth'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates soil type
         */
        case 'validate-soil-type': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.soilType === undefined) {
                newState.errors['soilType'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates support
         */
        case 'validate-support': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.supportType === undefined) {
                newState.errors['supportType'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates problematic ground
         */
        case 'validate-problematic-ground': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.problematicGround === undefined) {
                newState.errors['problematicGround'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates problematic ground
         */
        case 'validate-large-surcharges': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.largeSurcharges === undefined) {
                newState.errors['largeSurcharges'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates problematic ground
         */
        case 'validate-sloping-ground': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.slopingGround === undefined) {
                newState.errors['slopingGround'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates problematic ground
         */
        case 'validate-adjacent-water': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.adjacentWater === undefined) {
                newState.errors['adjacentWater'] = 'invalid';
            }
            return newState;
        }
        /**
         * Validates the sheeted solution type
         */
        case 'validate-sheeted-solution-type': {
            const newState = { ...state };
            // Reset the errors before recalculating them
            newState.errors = {};
            // Check for a valid postcode
            if (newState.form.sheetedSolutionType === undefined) {
                newState.errors['sheetedSolutionType'] = 'invalid';
            }
            return newState;
        }
        case 'set-modal': {
            const newState = { ...state };
            newState.modal = action.payload;
            return newState;
        }
        case 'set-footer': {
            const newState = { ...state };
            newState.stepFooter = { ...newState.stepFooter, ...action.payload };
            if (!action.payload.type) {
                newState.stepFooter.type = 'default';
            }
            return newState;
        }
        case 'set-tab': {
            const newState = { ...state };
            newState.tab = action.payload;
            if (newState.tab === Tab.YourSolution) {
                newState.steps = YourSolutionProgression;
            }
            else {
                newState.steps = YourSolutionPlusProgression;
            }
            return newState;
        }
        case 'toggle-locked': {
            const newState = { ...state };
            newState.scrollLocked = !newState.scrollLocked;
            return newState;
        }
        case 'set-locked': {
            const newState = { ...state };
            newState.scrollLocked = action.payload;
            return newState;
        }
        case 'set-soil-profiles': {
            const newState = { ...state };
            newState.soilProfiles = action.payload;
            return newState;
        }
        case 'set-surcharges': {
            const newState = { ...state };
            newState.surcharges = action.payload;
            return newState;
        }
        /**
         * Submit YSP success
         */
        case 'submit-ysp-success': {
            const newState = { ...state };
            newState.yspResults = action.payload;
            newState.loadingResults = false;
            newState.formDirtyAfterSolutionsFetch = false;
            return newState;
        }
        /**
         * Submit Solution success
         */
        case 'submit-solution-success': {
            const newState = { ...state };
            newState.selectedSolution = action.payload;
            newState.loadingResults = false;
            newState.hasChangedSelectedSolution = false;
            return newState;
        }
        case 'set-mobile': {
            const newState = { ...state };
            newState.mobile = action.payload;
            return newState;
        }
        case 'set-zoom-modal': {
            const newState = { ...state };
            newState.zoomModalOpen = action.payload;
            return newState;
        }
        case 'set-soil-modal': {
            const newState = { ...state };
            newState.soilModalOpen = action.payload;
            return newState;
        }
        case 'swap-to-ys-plus': {
            // This will swap the user over from YS to YS+ //
            const newState = { ...state };
            newState.step = StepID.TermsAndConditions;
            newState.tab = Tab.YourSolutionPlus;
            newState.steps = YourSolutionPlusProgression;
            newState.progress = 0;
            newState.globalStep = 0;
            return newState;
        }
        case 'set-has-added-default-surcharge': {
            const newState = { ...state };
            newState.hasAddedDefaultSurcharge = action.payload;
            return newState;
        }
    }
}
