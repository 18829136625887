import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import { m } from 'framer-motion';
import styled from 'styled-components';
import { FormStyles } from '../../Shared/Form.styles';
const Intro = styled.div `
  text-align: center;
  color: ${brand.grey.grey20};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${FormStyles.Form} {
    margin-bottom: 15px;
  }

  img {
    margin-bottom: 50px;
  }

  p {
    max-width: 630px;
    font-size: 15px;
    line-height: 20px;
    margin: 0px auto 15px;

    &:last-of-type {
      margin-bottom: 5px;
    }

    @media ${from(Device.TabletLarge)} {
      font-size: 19px;
      line-height: 25px;
      margin: 0px auto 24px;
    }
  }
`;
const IntroTitleWrapper = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  gap: 6px;
  h1 {
    font-size: 25px;
    color: rgb(51, 51, 51); 
  }
  @media ${from(Device.TabletLarge)} {
    font-size: 35px;
    margin-bottom: 5px;
    gap: 10px;
    h1 {
      font-size: 35px; 
    }
  }
`;
const Title = styled.div `
  ${fonts.DaxPro.Bold};

  span {
    line-height: 30px;
  }

  span:last-child {
    color: ${brand.primary.alt};
  }
`;
const TitleAndPlusWrapper = styled.div `
  display: flex;
  align-items: center;
  position: relative;
`;
const PleaseSelectText = styled.div `
  ${fonts.DaxPro.Medium};
  font-size: 20px;

  margin-bottom: 15px;
`;
const Plus = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            x: 15,
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  font-size: 32px;
  color: #f39200;
  ${fonts.DaxPro.Bold};
  position: absolute;
  left: 100%;

  @media ${from(Device.TabletLarge)} {
    font-size: 42px;
  }
`;
const WelcomeText = styled.span `
  font-weight: 600;
  display: inline; 
  margin-right: 10px;
`;
const CenteredDiv = styled.div `
   display: flex;
   alignItems: center;
   @media (max-width: 768px) {
     flex-direction: column;
   }
`;
export const LandingStyles = {
    Intro,
    IntroTitleWrapper,
    Title,
    PleaseSelectText,
    Plus,
    TitleAndPlusWrapper,
    WelcomeText,
    CenteredDiv,
};
