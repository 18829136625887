import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled from 'styled-components';
const Wrapper = styled.div `
  // Needed for the horizontal scroll table
  overflow: auto;
  line-height: normal;

  h1,
  h2 {
    ${HeadingStyles.H5Styles};
    color: ${brand.primary.alt};
  }

  p {
    ${ParagraphStyles.RegularStyles};
  }

  a {
    color: ${brand.primary.alt};
  }

  table {
    table-layout: fixed;
    min-width: 700px;
    width: 100%;

    thead {
      tr {
        background: ${brand.grey.grey35};
      }
    }

    tbody {
      tr {
        background-color: ${brand.grey.grey96};

        &:nth-child(odd) {
          background-color: rgba(102, 153, 0, 0.12);
        }
      }
    }

    th,
    td {
      padding: 12px 20px;
      width: 216px;

      @media ${from(Device.DesktopLarge)} {
        padding: 12px 34px;
      }
    }

    td {
      color: ${brand.black};

      border-right: 1px solid ${brand.grey.grey44};
      &:last-child {
        border-right: none;
      }
    }

    th {
      ${fonts.DaxPro.Bold};
      color: ${brand.white};
      font-size: 20px;
    }
  }
`;
export const WYSIWYGStyles = {
    Wrapper,
};
