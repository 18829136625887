import brand from '@helpers/brand';
import styled, { css } from 'styled-components';
import { Tab as TabEnum } from '@ys/Context/types';
import { m } from 'framer-motion';
import { Device, from, until } from '@helpers/media';
const Container = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            height: 0,
        },
        visible: {
            height: 'var(--ysp-header-height)',
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  width: 100%;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const Tab = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${brand.grey.grey89};

  img {
    user-select: none;
    z-index: 2;
    width: 160px;

    @media ${from(Device.TabletLarge)} {
      width: 300px;
    }
  }
`;
const BottomLine = styled(m.div) `
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  border-bottom: 3px solid ${brand.primary.alt};
`;
const BackgroundEffect = styled(m.div) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 3px solid ${brand.primary.alt};
  background-color: ${brand.white};
  z-index: 1 !important;

  ${({ tab }) => tab === TabEnum.YourSolution
    ? css `
          border-right: 3px solid ${brand.primary.alt};
        `
    : css `
          border-left: 3px solid ${brand.primary.alt};
        `}
`;
const BackToMainSiteMobile = styled(m.div).attrs(() => ({
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
    variants: {
        hidden: {
            height: 0,
        },
        visible: {
            height: 'var(--ysp-header-close)',
        },
    },
    transition: {
        duration: 0.5,
    },
})) `
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  @media ${from(Device.TabletLarge)} {
    display: none;
  }

  svg {
    &:first-of-type {
      // Logo //
      width: 200px;
    }
    &:last-of-type {
      // Close //
      width: 20px;
    }
  }
`;
const Image = styled.img `
  height: 100%;
  object-fit: contain;

  @media ${until(Device.TabletLarge)} {
    max-width: 50%;
  }
`;
export const TabsStyles = {
    Container,
    Tab,
    BackgroundEffect,
    BottomLine,
    BackToMainSiteMobile,
    Image,
};
